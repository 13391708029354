import DashboardLayout from "@/pages/layouts/DashboardLayout";

let reservationRoutes = {
  path: '/rsvp',
  component: DashboardLayout,
  redirect: '/rsvp/list',
  children: [
    {
      path: 'list',
      name: 'rsvp List',
      component:()=>import(/* webpackChunkName: "join-us" */ '@/pages/general/Reservation/ReservationList'),
      meta: {
        permissionCode: 'rsvp/index',
      }
    }
  ]
}

export default reservationRoutes;
